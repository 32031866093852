thead button {
  border: 0;
  border-radius: none;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0.5em;
  margin-bottom: 1px;
}

thead button.ascending::after {
  content: '👇';
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: '☝️';
  display: inline-block;
  margin-left: 1em;
}

.exportColumn {
  text-align: right!important;
}

.exportColumn a {
  color: black;
  font-size: 0.5em;
  text-decoration: underline;
}