html,
#root {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI Web (West European)", "Segoe UI", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 1.4286;
  height: 100%;
}

@media (max-width: 768px) {
  html,
  #root {
    font-size: 14px;
    line-height: 1.2286;
  }
}

.navbar {
    background: #FFBE00;
    margin-bottom: 1em;
}

.dropdownmargin {
    margin-bottom: 20px !important;
    }

.navbar-item img {
    margin-left: 40px;
}

.table td {
    vertical-align: middle;
}

table td .button {
    margin-left: 12px;
}

table td.actions {
    text-align: left;
    padding-left: 0px;
}

.section {
    padding-top: 1em;
}

.referenzform {
    /*padding-top: 2rem;*/
    background-color: white;
}

.textarea {
    min-height: 11em !important;
}

.referenzform h2 {
    /* color: red; */
    font-size: 1.2em;
}

.button.is-primary {
    background-color: #FFBE00;
}

    .button.is-primary:hover {
        background-color: black;
    }

.button.is-danger:hover {
    background-color: black;
}

.checkbox {
    margin-right: 24px;
}

.select {
    width: 100%;
}

.projectnumbersselect .select {
    width: auto;
}

.selectFilter .select{
    width: auto;
}

hr {
    margin-top: 4px;
    margin-bottom: 4px;
}

.selectionButton {
    margin-bottom: 8px;
}

.infoDetails {
    padding: 16px;
    /*background-color: #F0C02D;*/
    background-color: #F9F8F7;
    border-radius: 8px;
    margin-bottom: 1em;
}

.is-danger.input {
    border-color: red;
    border-width: 1px;
}

.help.is-danger {
    padding: 4px;
    margin-top: 8px;
    background-color: #E3982F;
    color: #4E4943;
    font-weight: 700;
    border-radius: 4px;
    display: block;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

    .arrow.ascending {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid #42b983;
    }

    .arrow.descending {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #42b983;
    }

.react-datepicker-wrapper {
    width: 100%;
}